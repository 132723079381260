import { useState, useEffect } from 'react';
import { Row, Tabs, Tab, Image, OverlayTrigger , Tooltip} from 'react-bootstrap';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { wishProduct } from '../../../services/productData'
import {forEach} from "react-bootstrap/ElementChildren";

function ProductInfo({ params }) {
    const [wish, setWish] = useState(false);

    useEffect(() => {
        if (params.isWished === true) {
            setWish(true)
        } else {
            setWish(false)
        }
    }, [params.isWished, setWish])

    const onHearthClick = () => {
        if (wish === false) {
            wishProduct(params._id)
                .then(res => {
                    setWish(true);
                })
                .catch(err => console.log(err))
        } else {
            wishProduct(params._id)
                .then(res => {
                    setWish(false);
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <>
            <Image className="col-lg-12" src={params.images_webp[0]} rounded />
            <Row>
                <h1 className="col-lg-10 col-sm-10 product-info-heading">{params.car_name}</h1>
                <span id="heartIconDetails" className="col-lg-1 col-sm-1" onClick={onHearthClick}>
                {params.isAuth && <>
                    {!wish ? (
                        <OverlayTrigger placement="top" overlay={<Tooltip>Add to Wishlist</Tooltip>}>
                            <BsHeart />
                        </OverlayTrigger>
                    )
                        : (
                            <OverlayTrigger placement="top" overlay={<Tooltip>Remove from Wishlist</Tooltip>}>
                                <BsHeartFill />
                            </OverlayTrigger>
                        )
                    }
                </>}
                </span>
            </Row>
            <div id="detailsCardText" className="col-lg-12">
                {params.car_models.map((car_model) =>
                    <Tabs defaultActiveKey="details" transition={false}>
                        <Tab eventKey="details" title={car_model.car_model_name} id="tab-details">
                            {car_model.param_sets.map((param_set) =>
                                {
                                    return param_set.params.map((car_param) =>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <p>{car_param.name}</p>
                                            <p>{car_param.value}</p>
                                        </div>
                                    )
                                }
                            )}
                        </Tab>
                    </Tabs>
                )}

                <Tabs defaultActiveKey="details" transition={false}>
                    <Tab eventKey="details" title="Parameters" id="tab-details">
                        {}

                        <hr />
                        <p id="details-footer" className="text-muted">Product listed at {params.addedAt}</p>
                    </Tab>
                    {/*<Tab eventKey="aboutSeller" title="About seller">*/}
                    {/*    <p>Name: {params.car_name || "Not specified"}</p>*/}
                    {/*    <p>Email: {params.car_name}</p>*/}
                    {/*    <p>Telephone: {params.car_name}</p>*/}
                    {/*    <p>City: {params.car_name}</p>*/}
                    {/*</Tab>*/}
                </Tabs>
            </div>
        </>
    )
}

export default ProductInfo;