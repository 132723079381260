import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BreadcrumbNav({ params }) {
    return (
        <Breadcrumb>
            <li className="breadcrumb-item">
                <Link to="/">Home</Link>
            </li>
            {/*<li className="breadcrumb-item">*/}
            {/*    <Link to={`/categories/${params.category}`}>{params.car_name}</Link>*/}
            {/*</li>*/}
            <li  className="breadcrumb-item">
                <Link to={`/categories/${params.id}/details`}>{params.car_name}</Link>
            </li>
        </Breadcrumb>
    )
}

export default BreadcrumbNav;