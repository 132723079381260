import { Card } from 'react-bootstrap';
// import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

function ProductCard({ params }) {
    return (
        <Card>
            <Link to={`/categories/${params.id}/details`}>
                <Card.Img variant="top" src={params.images[0]} />
                <Card.Body>
                    <Card.Title>{params.car_name}</Card.Title>
                    <Card.Text>{params.price_range}</Card.Text>
                </Card.Body>
            </Link>
            <Card.Footer>
                <small className="text-muted">
                    <Moment format="d MMM YYYY (dddd) HH:mm">
                        {/*{params.addedAt}*/}
                    </Moment>
                    {/*-  <strong>{params.city}</strong>*/}
                    {/* <Link to="" id="heartIcon"><BsHeart /></Link> */}
                </small>
            </Card.Footer>
        </Card>
    )
}

export default ProductCard;